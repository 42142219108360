import { ElementRef, Inject, Injectable } from '@angular/core';

import { firstValueFrom, ReplaySubject, Subject, Subscription } from 'rxjs';

import { LoadScriptService } from '../../../services/load-script.service';
import { LocalStorageService } from '../../../services/local-storage.service'; // TEMPORARY
import { SignInResponse } from '../interfaces/sign-in-response';
import { WINDOW } from '../../../services/window.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleSignInService {

  // Public ids
  public static readonly CLIENT_ID: string = '860022854282-gju1oofefi2c6rgp58aogl8allju4fmt.apps.googleusercontent.com';
  public static readonly PROVIDER_ID: string = 'GOOGLE';

  // Configuration https://developers.google.com/identity/gsi/web/reference/js-reference#GsiButtonConfiguration
  BUTTON_LOCALE: string = '';
  BUTTON_LOGO_ALIGNMENT: 'left' | 'center' = 'left';
  BUTTON_SHAPE: 'square' | 'circle' | 'pill' | 'rectangular' = 'rectangular';
  BUTTON_SIZE: 'small' | 'medium' | 'large' = 'medium';
  BUTTON_TEXT: 'signin' | 'signin_with' | 'signup_with' | 'continue_with' = 'signin_with';
  BUTTON_THEME: 'outline' | 'filled_blue' | 'filled_black' = 'outline';
  BUTTON_TYPE: 'icon' | 'standard' = 'standard';
  BUTTON_WIDTH: string = '200';

  // Observable subjects
  private googleSignInSubject = new Subject<SignInResponse>();
  private initializationSubject = new ReplaySubject(1);

  constructor(
    private loadScriptService: LoadScriptService,
    private localStorageService: LocalStorageService,
    @Inject(WINDOW) private window: Window | null
  ) { }

  // Publish google sign-in subject
  getGoogleSignInObservable() {
    return this.googleSignInSubject.asObservable();
  }

  initialize(): void {

    // Load Google Identity library
    this.loadScriptService.loadScript(GoogleSignInService.PROVIDER_ID, 'https://accounts.google.com/gsi/client', this.onScriptLoaded.bind(this));
  }

  refreshToken(idToken: string): Promise<any> {
    return new Promise((resolve, reject) => {
      firstValueFrom(this.initializationSubject)
        .then(() => google.accounts.id.revoke(idToken, (response) => {
          if (response.error) {
            google.accounts.id.prompt((notification) => {

              // If user canceled the prompt, sign out
              if (notification.isSkippedMoment()) {
                this.googleSignInSubject.next(null);
              }
            });
            reject(response.error);
          } else {
            resolve(response);
          }
        }));
    });
  }

  renderButton(elem: ElementRef, configuration: any = null): void {

    // TEMPORARY - only for testers
    // if (!this.localStorageService.getItem('TESTER')) return;

    firstValueFrom(this.initializationSubject)
      .then(() => google.accounts.id.renderButton(elem.nativeElement, {
        locale: this.BUTTON_LOCALE,
        logo_alignment: this.BUTTON_LOGO_ALIGNMENT,
        shape: this.BUTTON_SHAPE,
        size: configuration?.Size || this.BUTTON_SIZE,
        text: configuration?.Text || this.BUTTON_TEXT,
        theme: this.BUTTON_THEME,
        type: this.BUTTON_TYPE,
        width: configuration?.Width || +this.BUTTON_WIDTH,
      }));
  }

  signOut() {
    firstValueFrom(this.initializationSubject)
      .then(() => google.accounts.id.disableAutoSelect());
  }

  // From https://github.com/abacritt/angularx-social-login/blob/master/projects/lib/src/providers/google-login-provider.ts#L205
  private decodeJwt(idToken: string): any {

    const base64Url = idToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      this.window.atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  onSignInResponseCallback = ({ credential }) => {
    const responsePayload = this.decodeJwt(credential);

    // Prepare external auth provider sign-in response object
    const signInResponse: SignInResponse = {
      AuthProvider: 'GOOGLE',
      Email: responsePayload.email,
      FirstName: responsePayload.given_name,
      LastName: responsePayload.family_name,
      SessionInfo: null,
      Status: null,
      Token: credential
    };
    this.googleSignInSubject.next(signInResponse);
  }

  // Once the script is loaded, start watching incoming credentials
  private onScriptLoaded() {
    google.accounts.id.initialize({
      client_id: GoogleSignInService.CLIENT_ID,
      auto_select: true,
      callback: this.onSignInResponseCallback.bind(this),
      // prompt_parent_id: this.initOptions?.prompt_parent_id,
      itp_support: true
    });

    this.initializationSubject.next(true);
  }
}
