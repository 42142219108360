import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadScriptService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) { }

  // From https://github.com/abacritt/angularx-social-login/blob/master/projects/lib/src/entities/base-login-provider.ts
  loadScript(
    id: string,
    src: string,
    onload: any,
    parentElement = null
  ): void {

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // Get document if platform is only browser
    if (typeof this.document !== 'undefined' && !this.document.getElementById(id)) {
      let newScript = this.document.createElement('script');

      newScript.async = true;
      newScript.src = src;
      newScript.onload = onload;

      if (!parentElement) {
        parentElement = this.document.head;
      }

      parentElement.appendChild(newScript);
    }
  }
}
