import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private storage: Storage | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage = localStorage;
    }
  }

  clear() {
    return this.storage && this.storage.clear();
  }

  getItem(key) {
    return this.storage && this.storage.getItem(environment.localStoragePrefix + key);
  }

  removeItem(key) {
    return this.storage && this.storage.removeItem(environment.localStoragePrefix + key);
  }

  setItem(key, value) {
    return this.storage && this.storage.setItem(environment.localStoragePrefix + key, value);
  }
}

