import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import { LocalStorageService } from '@shared/services/local-storage.service';
import { ResizeService } from './resize.service';
import { Telemetry } from '../interfaces/telemetry';
import { TelemetryDataService } from './telemetry-data.service';
import { WINDOW } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class TelemetryService {

  injectedElements: any = {}; // Elements that we've injected
  isCustomer = false; // Is the user a customer?
  isEmployee = false; // Is the user an employee?

  private screenSize = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: object,
    private resizeService: ResizeService,
    private router: Router,
    private telemetryDataService: TelemetryDataService,
    @Inject(WINDOW) private window: Window | null
  ) {

    // Get the current screen size
    this.resizeService.onResize.subscribe(size => this.screenSize = size);
  }

  inject(event: string, elementId: string, sourcePath: string, onlyCustomer: boolean) {

    // Get the element(s) to inject
    const querySelector = '[id^="' + elementId + '"]';
    const elements = isPlatformBrowser(this.platformId) ? this.document.querySelectorAll(querySelector) : [];

    // First check that we can inject this element!
    if (this.canInject(onlyCustomer)) {

      elements.forEach((element: any) => {

        // Set the payload (Telemetry) object to the element
        // Remove any query params from the sourcePath
        element.payload = <Telemetry>{
          ClientId: this.localStorageService.getItem('CLIENT'),
          UserAgent: this.window && (this.window.navigator.userAgent || this.window.navigator.vendor),
          Event: event,
          Element: element.id,
          SourcePath: sourcePath ? sourcePath.split('?')[0] : this.router.url.split('?')[0],
          DestinationPath: element.getAttribute('tm-destination')
        };

        // Set the event listener based on the Telemetries event
        // Currently only CLICK is supported, but other event types could be added in the future
        switch (event) {
          case 'CLICK':

            // Add the event listener
            element.addEventListener('click', this.handleClick, false);

            // Add the injected element into our hash for reference
            // (e.g. we may need to remove event listeners when staff logs in)
            const injectedElement = { element: element, type: 'click', listener: this.handleClick };
            this.injectedElements[element.id] = injectedElement;
            break;
        }
      });
    }
  }

  remove() {

    // For each of the injected elements, remove the listener that we've added
    Object.values(this.injectedElements).forEach((injectedElement: any) => {
      injectedElement.element.removeEventListener(injectedElement.type, injectedElement.listener);
    });

    // Clear the array of injected elements
    this.injectedElements = {};
  }

  setIsCustomer(isCustomer: boolean) {
    this.isCustomer = isCustomer;
  }

  setIsEmployee(isEmployee: boolean) {
    this.isEmployee = isEmployee;
  }

  // Can we inject the element?
  private canInject(onlyCustomer: boolean): boolean {
    return !this.isEmployee || (this.isEmployee && !onlyCustomer);
  }

  // Click callback
  private handleClick = (element: any) => {

    // Get the payload object from the element
    const payload = element.currentTarget.payload;

    // Set the current screen size
    payload.ScreenSize = this.screenSize;

    // Create the Telemetry record
    this.telemetryDataService.create(payload)
      .subscribe(
          // (value) => console.debug('TELEMETRY SAVED: ' + JSON.stringify(payload)),
          // (error) => console.debug('TELEMETRY ERROR: ' + JSON.stringify(error))
        );
  }
}
